import React, { ReactNode } from 'react';

import { useAppSelector } from '../../../store/hooks';
import { selectOpenWindows, WindowType } from '../../../store/windows/windows.slice';
import { Window } from '../../shared/window/window';
import { WINDOWS } from '../../apps/apps.const';

export const OpenWindows = () => {
    const openWindows = useAppSelector(selectOpenWindows);

    const visibleWindows = openWindows.map(({type, id, props}) => ({
        id,
        appProps: props,
        ...WINDOWS[type],
        }));
    return (
        <>
        {
            visibleWindows.map((
                {
                    id,
                    appProps,
                    type,
                    component,
                    windowProps
                }: 
                {
                    id: string,
                    appProps?: any,
                    type: WindowType,
                    component: (props?: any) => ReactNode,
                    windowProps?: any
                }) => (
                <Window
                    key={type}
                    title={type}
                    windowId={id}
                    windowProps={windowProps}
                    zIndex={openWindows.findIndex((openWin) => openWin.type === type)} type={type}>
                    {component(appProps)}
                </Window>
            ))
        }
        </>
    );
};
