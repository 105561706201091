import React, { useEffect, useState } from 'react';

const MINUTE_MS = 1000 * 60;

function formatDate(dateTime: Date){
    const local = 'en-US';

    const time = new Intl.DateTimeFormat(
        local, 
        {
            hour12: true,
            hour: 'numeric',
            minute: '2-digit',
        }
    ).format(dateTime);
        
    const weekday = new Intl.DateTimeFormat(
        local, { weekday: 'short' }
    ).format(dateTime);

    const day = new Intl.DateTimeFormat(
        local, { day: 'numeric'}
    ).format(dateTime);

    const month = new Intl.DateTimeFormat(
        local, { month: 'short'}
    ).format(dateTime);

    return `${time} - ${weekday} ${month} ${day}, 1993`;
}

export const CurrentDate: React.FC = () => {
    const [dateTime, setDateTime] = useState(new Date());
    
    useEffect(() => {
        const id = setInterval(() => {
          setDateTime(new Date());
        }, MINUTE_MS);
        return () => clearInterval(id);
      }, []);

    
    return (
        <span>
            {formatDate(dateTime)}
        </span>
    );
};