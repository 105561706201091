import { makeStyles } from '@material-ui/core/styles';

export const TOPBAR_HEIGHT = '20px';

function iconSquare(topPadding: number){
  return `calc(${TOPBAR_HEIGHT} - ${topPadding}px)`
}
export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    borderBottom: `1px ${theme.palette.common.black} solid`,
    height: TOPBAR_HEIGHT,
    fontSize: '14px',
  },
  icon: {
    paddingTop: '3px',
    marginRight: '5px',
    height: iconSquare(5),
    width: iconSquare(5),
  },
  fullscreenIcon: {
    height: iconSquare(7),
    width: iconSquare(7),
    paddingTop: '2px',
  },
  boxed: {
    padding: `0 ${theme.spacing(1)}px`,
    borderLeft: `1px ${theme.palette.common.black} solid`,
  }
}));