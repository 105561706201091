import { Grid, TextField, Typography } from '@material-ui/core';
import React, {useState} from 'react';
import { navToPage, selectCurrentPageUrl } from '../../../../store/browser/browser.slice';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

import { BrowserControls } from './browserControls/browserControls';
import { useStyles } from './browserNav.styles';

export const BrowserNav: React.FC = () =>  {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const loadedUrl = useAppSelector(selectCurrentPageUrl);
    const [url, setUrl] = useState(loadedUrl);
    const [isDirty, setDirty] = useState(false);

    function onKeyPress(e: any){
        setDirty(true);
        // 13 is Enter which we use as a submit for a new url
        if(e.keyCode === 13){
            dispatch(navToPage(url));
            setDirty(false);
        }
    }
    return (
        <Grid
            container
            direction="column"
            className={classes.nav}>
                <BrowserControls />
                <Grid
                    item
                    container
                    direction="row"
                    className={classes.addressBar}
                    justify="flex-start"
                    alignItems="center">
                        <Grid
                            item
                            xs={2}
                            container
                            justify="space-between">
                                <Grid item xs={1}>
                                    <div className={classes.grabBars}></div>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        className={classes.addressText}
                                        variant="body1"
                                        >Address:</Typography>
                                </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={10}>
                            <TextField
                                onChange={(e) => { 
                                    setUrl(e.target.value);
                                }}
                                onKeyDown={(e) => onKeyPress(e)}
                                value={isDirty ? url : loadedUrl}
                                variant="outlined"
                                id="address-input"
                                autoComplete="off"
                                focused={true}
                                fullWidth
                                classes={{
                                    root: classes.inputRoot,
                                }}
                                InputLabelProps={{
                                    disableAnimation: true,
                                    classes: {
                                        root: classes.addressInputText,
                                    },
                                }}
                                InputProps={{ 
                                    notched: false,
                                    classes: {
                                        notchedOutline: classes.notchedOutline,
                                        input: classes.inputInput,
                                    }
                                }}
                                />
                        </Grid>

                </Grid>
        </Grid>
    );
};