import { makeStyles } from '@material-ui/core/styles';
import { WINDOW_TOPBAR_HEIGHT } from '../../../shared/window/windowTopbar.styles';

export const useStyles = makeStyles((theme) => ({
  image: {
      backgroundColor: theme.palette.common.black,
      position: 'absolute',
      top: `calc(50% + (${WINDOW_TOPBAR_HEIGHT}/ 2))`,
      left: '50%',
      transform: `translateX(-50%) translateY(-50%)`,
      maxWidth: '100%',
      maxHeight: `calc(100% - ${WINDOW_TOPBAR_HEIGHT})`,
  },
}));