import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: theme.palette.grey[200],
    '& > h1': {
      fontWeight: 'bol',
    },
    '& > img': {
      width: '50%',
      margin: '0 auto',
    }
  },
  title: {
    margin: '.25em',
  },
}));