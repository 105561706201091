import React from "react";
import { WindowType } from "../../store/windows/windows.slice";

import { About } from "./about/about";
import { Design } from "./design/design";
import { Projects } from "./projects/projects";
import { Resume } from "./resume/resume";
import { Theme } from "./theme/theme";
import { ImageViewer, ImageViewerProps } from "./trash/imageViewer/imageViewer";
import { Trash } from "./trash/trash";

// for URL sharing
export const SHARABLE_URL_KEY = 'sharable';

export const WINDOWS = {
    // top bar
    [WindowType.About]: {
        type: WindowType.About,
        component: () => (<About />),
        windowProps: {
            minHeight: '500px',
            defaultHeight: '500px',
            defaultWidth: '550px',
        }
    },
    [WindowType.Themes]: {
        type: WindowType.Themes,
        component: () => (<Theme />),
        windowProps: {
            maxWidth: '350px',
            minHeight: '350px',
            maxHeight: '400px',
        }
    },
    // desktop apps
    [WindowType.Resume]: {
        type: WindowType.Resume,
        component: () => (<Resume />),
        windowProps: {
            defaultHeight: '500px',
            defaultWidth: '800px',
        }
    },
    [WindowType.Design]: {
        type: WindowType.Design,
        component: () => (<Design />),
        windowProps: {
            defaultHeight: '600px',
            defaultWidth: '600px',
        }
    },
    [WindowType.Trash]: {
        type: WindowType.Trash,
        component: () => (<Trash />),
        windowProps: {
            defaultWidth: '300px',
        }
    },
    [WindowType.Projects]: {
        type: WindowType.Projects,
        component: () => (<Projects />),
        windowProps: {
            defaultHeight: '600px',
        }
    },
    [WindowType.Games]: {
        type: WindowType.Games,
        component: () => null,
    },
    // secondary
    [WindowType.Image]: {
        type: WindowType.Image,
        component: (props: ImageViewerProps) => (<ImageViewer {...props}/>),
        windowProps: {
            minWidth: '30px',
            minHeight: '30px',
            defaultWidth: '300px',
        }
    }
};