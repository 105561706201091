import { makeStyles } from '@material-ui/core/styles';

import patternCat from '../../../assets/images/cup-pattern.png';

const backgroundColor = '#c4f1f2';

export const useStyles = makeStyles((theme) => ({
  // the patterned background for the window
  tiledBackground: {
    backgroundImage: `url(${patternCat})`,
    backgroundRepeat: 'repeat',
    width: '100%',
    height: '100%',
    overflowY: 'hidden',
  },

  // the scroling ticker
  headerContainer: {
    overflow: 'hidden',
    backgroundColor: backgroundColor,
    padding: '0.5rem 0',
    borderTop: '3px solid #A127B1',
    borderBottom: '3px solid #A127B1',
    marginBottom: '2rem',
    [theme.breakpoints.down('md')]: {
      marginBottom: 0,
    },
  },
  "@keyframes ticker": {
    "0%": { transform: 'translate3d(100%, 0, 0)' },
    "100%": { transform: 'translate3d(-50%, 0, 0)' },
  },
  headerTickerContent: {
    animation: '$ticker linear 10s infinite',
    '&:hover': {
      animationPlayState: 'paused',
    }
  },
  name: {
    width: 'max-content',
    padding: '0 1em',
    display: 'inline',
    whiteSpace: 'nowrap',
  },

  text: {
      backgroundColor: backgroundColor,
      padding: '1rem',
      margin: '1rem',
      '& > *': {
        margin: '.5rem 0',
      },
      [theme.breakpoints.down('md')]: {
        marginTop: '0.5em',
      },
  },
  links: {
      textAlign: 'center',
      justifySelf: 'center',
      '& > li': {
        backgroundColor: '#ffb3d9',
        padding: '0.2em',
        float: 'left',
        display: 'list-item',
        listStyleType: 'disc',
        marginRight: '2rem',
        transition: '0.8s',
        '&:hover': {
          backgroundColor: '#FF69B4',
        },
        [theme.breakpoints.down('md')]: {
          margin: '0 8rem 0.5em 0',
        },
      },
  },
  mirror: {
    transform: 'scaleX(-1)',
  },
  gif: {
    width: '20%',
    height: '20%',
    maxWidth: '100px',
    maxHeight: '100px',
  },
  cup: {
    width: '100px',
    height: 'auto',
    float: 'right',
    margin: '-23px',
    marginBottom: '23px',
  }
}));