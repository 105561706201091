import React, { ReactNode } from 'react';
import { FourOhFour } from './pages/fourOhFour/fourOhFour';
import { Home } from './pages/home/home';

export interface PageInfo {
    title: string;
    url: string;
    component: ReactNode
}

export const HOME_PAGE: PageInfo = {
    title: 'Home',
    url: 'http://wwww.mkusold.com/ie/home',
    component: (<Home />),
};

export const NOT_FOUND: PageInfo =  {
    title: '404',
    url: '',
    component: (<FourOhFour/>),
};

export const AVAILABLE_WEB_PAGES: Array<PageInfo> = [
  HOME_PAGE,  
];