import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import { useStyles } from './windowTopbar.styles';
import clsx from 'clsx';
import { Button } from '../button/button';

interface Props {
    title: string;
    onClose: () => void,
    dragHandleClassName: string,
}

export const WindowTopbar: React.FC<Props> = ({
    title,
    onClose,
    dragHandleClassName,
}) => {
    const classes = useStyles();
    return (
        <Grid 
            container
            direction="row"
            justify="space-between"
            alignItems="stretch">
            <Grid item className={clsx(classes.title, dragHandleClassName)}>
                <Typography variant="h6">{title}</Typography>
            </Grid>
            <Grid item className={clsx(classes.fullWidth, dragHandleClassName)}>
                <div className={classes.spacer}></div>
            </Grid>
            <Grid item>
                <Button
                    onClick={onClose}
                    className={classes.close}>
                        X
                    </Button>
            </Grid>
        </Grid>
    );
};
