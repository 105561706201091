import ReactDOMServer from 'react-dom/server'

export const resume = () => {
    return ReactDOMServer.renderToString(
        <>
            <h1>{`// Milo Kusold //`}</h1>
            <h2>Education</h2>
                <i>{`// The Ohio State University`}</i>
                <p>
                    Bachelor of Science, 2015 <br/>
                    Computer Science Engineering <br/>
                    Design Minor <br/>
                </p>
            <h2>Skills</h2>
            <i>{`// Languages`}</i>
            <ul>
                <li>JS & Node.js</li>
                <li>HTML & CSS</li>
                <li>Python</li>
                <li>Go</li>
                <li>C#</li>
            </ul>
            <i>{`// Frameworks / Libraries`}</i>
            <ul>
                <li>React</li>
                <li>Angular</li>
                <li>Electron</li>
                <li>GraphQL</li>
                <li>ROS</li>
                <li>Openlayers</li>
                <li>Arduino</li>
            </ul>
            <i>{`// Design`}</i>
            <ul>
                <li>Figma</li>
                <li>Logos</li>
                <li>Wireframing</li>
                <li>Web</li>
            </ul>
            <h2>Experience</h2>
            <p>
                <i>{`// Allen Institute for Artificial Intelligence`}</i>  <br/>
                Sr. Software Engineer [2021 - 2022] <br/>
                <br/>
                Supported Artificial Intelligence researchers by exposing and showcasing their work through visually compelling, React-based demos, expanding and maintaining internal Go, python, & Docker tools that allowed researchers to easily create and host their own demos if they were able, creating internal infrastructure to keep track of publications and datasets, and supported internal tools that researchers used to perform experiments.
            </p>
            <p>
                <i>{`// Optimize Health`}</i>  <br/>
                Software Engineer [2020 - 2021] <br/>
                <br/>
                Worked on supporting the core product, which focused on remote patient monitoring.
                Other activities included: on-call rotations, debugging hardware device logs, and setting up JIRA instances for the team.
            </p>
            <p>
                <i>{`// Fresh Consulting`}</i>  <br/>
                Sr. Software Developer [2019 - 2020] <br/>
                <br/>
                <b>Autonomous Robotic Air Hockey Project:</b> <br/>
                Acted as the overall UI/UX designer and Interactive Software Developer.  <br/>
                <br/>
                Designed the overall game experience as well as all digital screens, sounds, and lighting aspects of the game. Developed interactive software across 3 separate languages and frameworks including Angular ( Typescript), ROS (Python) and Arduino (~cpp) for the screens, robotic API, and lights/goals hardware integrations respectively. Packaged and deployed the system utilizing Docker and custom kiosk configurations. <br/>
                <br />
                <b>ROS Over the Air Updates Project:</b> <br/>
                Designed and implemented early prototypes for a custom over the air update strategy for autonomous industrial robots. <br />
            </p>
            <p>
                <i>{`// Fresh Consulting`}</i>  <br/>
                Full Stack Developer [2017 - 2019] <br/>
                <b>Autonomous Construction Vehicles Project:</b> <br/>
                <br/>
                Acted as the Dev Lead, Technical Architect, intermittent Project Manager, and Scrum Master on a team of 5 developers to create a cross-platform desktop application that interfaced with autonomous construction vehicles. The application communicated with and monitored vehicles (primarily Bobcats), mapped sites, and planned vehicle routes. Primary technologies that were used include Electron, Node.js, React, Redux, GraphQL, and Openlayers maps. This project required learning mapping and basic GIS skills to be able to communicate effectively with surveyors and translate measurements onto performant maps within the application.<br/>
                <br/>
                <b>Robotics Indoor Positioning System Project:</b> <br/>
                Collaborated on a custom indoor positioning system that relies on computer vision utilizing python, openCV, raspberry pi -- ultimately achieving positional accuracy within 2cm.<br />
                <br/>
                <b>Legal Discovery Project:</b> <br/>
                Created an Angular web app to help organize and annotate legal documents. Developed extended online PDF functionality including note taking, highlighting, overlays, and the ability to link phrases between multiple PDFs.<br />
            </p>
            <p>
                <i>{`// HMB`}</i>  <br/>
                Associate Consultant [2016 - 2017]<br/>
                <br/>
                Created mobile and web applications for a range of clients using AngularJS and .NET. Participated in relational database design. Consulted amongst my teams for my knowledge of front-end development and a keen eye for design and improving user experience.<br/>
            </p>
            <p>
                <i>{`// Rosetta Junior Associate Consultant [2014]`}</i>  <br/>
            </p>
            <p>
                <i>{`// OCLC Automation Engineering Intern [2013]`}</i>  <br/>
            </p>
            <h2>Let's Talk</h2>
            <ul>
                <li>Phone: (440) 665-2581</li>
                <li>Email: m@kusold.com</li>
            </ul>
        </>
    )
}
