import WindyCityTTF from '../assets/fonts/WindyCity.ttf';

import GenevaNormalTTF from '../assets/fonts/geneva/Geneva-Normal.ttf';
import GenevaNormalItalicTTF from '../assets/fonts/geneva/Geneva-Normal-Italic.ttf';
import GenevaBoldTTF from '../assets/fonts/geneva/Geneva-Bold.ttf';
import GenevaBoldItalicTTF from '../assets/fonts/geneva/Geneva-Bold-Italic.ttf';

const windyCity: any = {
  fontFamily: 'WindyCity',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('WindyCity'),
    url(${WindyCityTTF}) format('truetype')
  `,
};

const genevaNormal: any = {
  fontFamily: 'Geneva-Normal',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Geneva'),
    local('Geneva-Normal'),
    url(${GenevaNormalTTF}) format('truetype')
  `,
};

const genevaNormalItalic: any = {
  fontFamily: 'Geneva-Normal-Italic',
  fontStyle: 'italic',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('GenevaNormalItalic'),
    local('Geneva-Normal-Italic'),
    url(${GenevaNormalItalicTTF}) format('truetype')
  `,
};

const genevaBold: any = {
  fontFamily: 'Geneva-Bold',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `
    local('GenevaBold'),
    local('Geneva-Bold'),
    url(${GenevaBoldTTF}) format('truetype')
  `,
};
const genevaBoldItalic: any = {
  fontFamily: 'Geneva-Bold-Italic',
  fontStyle: 'italic',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `
    local('GenevaBoldItalic'),
    local('Geneva-Bold-Italic'),
    url(${GenevaBoldItalicTTF}) format('truetype')
  `,
};

const fallbackFonts = [
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
];

const createFontFamily = (font: string) => {
  return [
    font,
    ...fallbackFonts,
  ].join(',');
}

export const typography = {
    fontFamily: createFontFamily('WindyCity'),
    secondaryFontFamily: createFontFamily('Geneva-Normal'),
    secondaryFontFamilyItalic: createFontFamily('Geneva-Normal-Italic'),
    secondaryFontFamilyBold: createFontFamily('Geneva-Bold'),
    secondaryFontFamilyBoldItalic: createFontFamily('Geneva-Bold-Italic'),
    h6: {
      fontFamily: createFontFamily('WindyCity'),
      fontWeight: 500,
      fontSize: '.85rem',
      lineHeight: 1.4,
      letterSpacing: "0.0075em"
    },
    body1: {
      fontFamily: createFontFamily('Geneva-Normal'),
      fontSize: '.75rem',
    }
};

export const typographyGlobalOverrides = {
    '@font-face': [
      windyCity,
      genevaNormal,
      genevaNormalItalic,
      genevaBold,
      genevaBoldItalic
    ],
}