import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import reportWebVitals from './reportWebVitals';
import { store } from './store/store';
import App from './app';
import { BrowserRouter } from 'react-router-dom';

function sayHiToTheCreeps(){
  const KUSOLD_OS_ASCII_ART =
String.raw`
|￣￣￣￣￣￣￣￣￣￣￣|
        HI!
|＿＿＿＿＿＿＿＿＿＿＿|
    \ (•◡•) /
     \     /
       ---
      |   |
`;
  console.log(KUSOLD_OS_ASCII_ART);
  console.log('=== Welcome to my desktop! ===');
  console.log('If you\'re ever interested in talking about development, hit me up on LinkedIn!');
  console.log('https://www.linkedin.com/in/mkusold/');
}

const Root = () => {
  sayHiToTheCreeps();
  return (
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </React.StrictMode>);
}

ReactDOM.render(
  <Root />,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
