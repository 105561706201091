import React from 'react';
import { Rnd } from 'react-rnd';
import { Box } from '@material-ui/core';
import { isMobile } from "react-device-detect";

import { useAppDispatch } from '../../../store/hooks';
import { useSearchParams } from 'react-router-dom';
import { closeWindow, bringToTop } from '../../../store/windows/windows.slice';
import { WindowTopbar } from './windowTopbar';

import { useStyles } from './window.styles';
import { useWindowSize, Size } from '../useWindowSize/useWindowSize';
import { SHARABLE_URL_KEY } from '../../apps/apps.const';

interface Props {
    title: string;
    windowId: string;
    zIndex: number;
    windowProps?: any;
    type: string;
}

const getInitialWindowPosition = (initX: number, initY: number, order: number) => ({
    x: initX + (20 * order),
    y: initY + (20 * order),
});

const BROWSER_DEFAULTS = {
    x: 25,
    y: 20,
    width: 420,
    height: 300,
    minWidth: 400,
    minHeight: 250,
    padding: 10,
}

const MOBILE_PADDING = 10;

function getWindowSizing(
    order:number,
    {width: currentWidth, height: currentHeight}: Size,
    {defaultWidth: defaultWidthOverride, defaultHeight: defaultHeightOverride}: {defaultWidth: number | null, defaultHeight: number | null}){
    
    const defaultWidth = defaultWidthOverride ?? currentWidth - (BROWSER_DEFAULTS.x * 4) - (BROWSER_DEFAULTS.padding * 4);
    const defaultHeight = defaultHeightOverride ?? BROWSER_DEFAULTS.height;
    
    return isMobile ? {
        x: 2,
        y: 2,
        width: defaultWidth < currentWidth && currentWidth ? defaultWidth : currentWidth - 3 * (MOBILE_PADDING),
        height: defaultHeight < currentHeight && currentHeight ? defaultHeight : currentHeight - 3 * (MOBILE_PADDING),
        minWidth: BROWSER_DEFAULTS.minWidth < currentWidth && currentWidth ? BROWSER_DEFAULTS.minWidth : currentWidth - 3 * (MOBILE_PADDING),
        minHeight: BROWSER_DEFAULTS.minHeight < currentHeight && currentHeight? BROWSER_DEFAULTS.minHeight : currentHeight - 3 * (MOBILE_PADDING),
    } : {
        ...BROWSER_DEFAULTS,
        width: defaultWidth < currentWidth && currentWidth ? defaultWidth : currentWidth - 3 * (MOBILE_PADDING),
        height: defaultHeight < currentHeight && currentHeight ? defaultHeight : currentHeight - 3 * (MOBILE_PADDING),
        // cascading x and ys based on open order
        ...getInitialWindowPosition(BROWSER_DEFAULTS.x, BROWSER_DEFAULTS.y, order),
    };
}

export const Window: React.FC<Props> = ({
    children,
    title,
    windowId,
    windowProps = {},
    zIndex,
    type,
}) => {
    const dispatch = useAppDispatch();
    const size = useWindowSize();
    const classes = useStyles();

    const [searchParams, setSearchParams] = useSearchParams();

    // get app dependent sizing info that's defined in apps.const.tsx
    const { defaultWidth, defaultHeight, ...otherWindowProps } = windowProps;
    const defaultWindowSizeOverrides = {
        defaultWidth: defaultWidth ? parseInt(defaultWidth, 10) : null,
        defaultHeight: defaultHeight ? parseInt(defaultHeight, 10) : null,
    };
    const {
        minWidth,
        minHeight,
        ...windowDefaults
    } = getWindowSizing(zIndex, size, defaultWindowSizeOverrides);
    
    return size.width && size.height ? (
            <Rnd
            style={{zIndex: zIndex + 1}}
            className={classes.window}
            default={windowDefaults}
            minWidth={minWidth}
            minHeight={minHeight}
            bounds="parent"
            dragHandleClassName="handle"
            {...otherWindowProps}
        >
             <Box
                display="flex"
                flexDirection="column"
                height="100%"
                >
                    <div className={classes.draggable}>
                        <WindowTopbar 
                            dragHandleClassName="handle"
                            title={title}
                            onClose={() => {
                                if (searchParams.has(SHARABLE_URL_KEY)) {
                                    const activeShareable = searchParams.get(SHARABLE_URL_KEY);
                                    if(activeShareable === type){
                                        searchParams.delete(SHARABLE_URL_KEY);
                                    }
                                    setSearchParams(searchParams);
                                }
                                dispatch(closeWindow(windowId))}
                            }/>
                    </div>
                    <Box
                        flexGrow={1}
                        display="flex"
                        overflow="scroll"
                        onClick={() => dispatch(bringToTop(windowId))}
                        style={{height: '100%'}}>
                        {children}
                    </Box>
            </Box>
        </Rnd> 
        ) : null;
};
