import React from 'react';
import { selectCurrentPage } from '../../../store/browser/browser.slice';
import { useAppSelector } from '../../../store/hooks';
import { BrowserContent } from '../../shared/browser/browserContent/browserContent';
import { BrowserNav } from '../../shared/browser/browserNav/browserNav';
import { FourOhFour } from './pages/fourOhFour/fourOhFour';

import { useStyles } from './projects.styles';

export const Projects: React.FC = () =>  {
    const classes = useStyles();

    const currentPage = useAppSelector(selectCurrentPage);
    return (
        <>
            <div className={classes.wrapper}>
                <BrowserNav />
                <BrowserContent>
                    { currentPage ? currentPage.component : <FourOhFour /> }
                </BrowserContent>
            </div>
        </>
    );
};