import React from 'react';
import { Link as MuiLink } from '@material-ui/core';

interface Props {
    [x:string]: any;
}

export const Link: React.FC<Props> = ({children, ...linkProps}) => {
    return (
        <MuiLink
            color="secondary"
            underline="always"
            {...linkProps}>
                {children}
        </MuiLink>
    );
};