import { makeStyles } from '@material-ui/core/styles';

const size = '150px';

export const useStyles = makeStyles((theme) => ({
  screensaver: {
    position: 'fixed',
    left: 0,
    top: 0,
    backgroundColor: 'blue',
    height: '100vh',
    width: '100vw',
    zIndex: 999,
  },

  screensaverSizing: {
    width: `${size}`,
    height: `${size}`,
  },

  screensaverImg: {
    borderRadius: '50%',
  },

  spin: {
    animation: `$rotation 5s infinite linear`,
  },

  xMovement: {
    animation: `$x 13s linear infinite alternate`,
  },

  yMovement: {
    animation: `$y 7s linear infinite alternate`,
  },

  "@keyframes x": { 
    '100%': {
      transform: `translateX( calc(100vw - ${size}))`,
    }
  },

  "@keyframes y": {
    '100%': {
      transform: `translateY( calc(100vh - ${size}) )`,
    }
  },

  "@keyframes rotation": {
    'from': {
      transform: 'rotate(0deg)',
    },
    'to': {
      transform: 'rotate(359deg)',
    }
  },

}));