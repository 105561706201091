import React from 'react';
import clsx from 'clsx';
import { Grid, Typography } from '@material-ui/core';

import { useStyles } from './about.styles';
import { Link } from '../../shared/link/link';

import walkingCatImg from '../../../assets/images/walking_cat.gif';
import canImg from '../../../assets/images/can.gif';

const WhiteKitty = ({identifier}: {identifier?: string;}) => {
    const classes = useStyles();
    return (<img key={identifier} className={clsx(classes.mirror, classes.gif)} src={walkingCatImg} alt="White cat walking"/>);
};

export const About: React.FC = () =>  {
    const classes = useStyles();
    return (
        <div className={classes.tiledBackground}>
            <Grid
                container
                direction="column"
                >
                <Grid
                    item>
                        <div className={classes.headerContainer}>
                            <div className={classes.headerTickerContent}>
                                <WhiteKitty identifier={'front'}/>
                                <h1 className={classes.name}>Milo Kusold</h1>
                                {<WhiteKitty identifier={'back'}/>}
                            </div>
                        </div>
                </Grid>
                <Grid item>
                    <ul className={classes.links}>
                        <li><Link href="https://www.linkedin.com/in/mkusold/" target="_blank">Linked In</Link></li>
                        <li><Link href="https://github.com/mkusold" target="_blank">Github</Link></li>
                        <li><Link href="mailto: m@kusold.com">Email m@kusold.com</Link></li>
                    </ul>
                </Grid>
                <Grid item>
                    <div className={classes.text}>
                        <Typography variant="body1">
                            Hi. My name is Milo.
                        </Typography>
                        <Typography variant="body1">
                            I'm passionate about integrating design with technology. I strive to make complex systems elegant. From design, to code, my guiding principles are simplicity and craftsmanship.
                        </Typography>
                        <Typography variant="body1">
                            In my free time, I enjoy investigating the world in areas of interest spanning from foraging, mycology, permaculture, logo design, robotics, baking, socialism, soap making, finances, alternative relationship structures, and environmentalism. I use they/them pronouns. 
                        </Typography>
                        <img className={classes.cup} src={canImg} alt="rotating can" />
                    </div>
                </Grid>
            </Grid>            
        </div>
    );
};