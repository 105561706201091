import React from 'react';

import { useStyles } from './imageViewer.styles';

export interface ImageViewerProps {
    name: string;
    src: string;
    alt: string;
}

export const ImageViewer: React.FC<ImageViewerProps> = ({src, alt}) =>  {
    const classes = useStyles();
    return (
        <img
        src={src}
        alt={alt}
        className={classes.image}/> 
    );
};