import { Grid } from '@material-ui/core';
import React from 'react';

interface Props {
    title: string;
    titleLink?: string;
    overview: string;
    roles: Array<string>;
    skills: Array<string>;
}
export const PortfolioItem: React.FC<Props> = ({
    title,
    titleLink,
    overview,
    roles,
    skills}) =>  {
    return (
        <>
            <h2>
                {
                    titleLink ? ( 
                    <a 
                        href={titleLink}
                        target="_blank"
                        rel="noreferrer">
                            {title}
                    </a>

                    ) : title
                }
            </h2>
            <h3>Overview</h3>
            <p>{overview}</p>
            <Grid container direction="row">
                <Grid item xs={6}>
                    <h3>Roles</h3>
                    <ul>
                        {
                            roles.map((role) => (<li key={role}>{role}</li>))
                        }
                    </ul>
                </Grid>
                <Grid item xs={6}>
                <   h3>Skills / Tech</h3>
                    <ul>
                        {
                            skills.map((skill) => (<li key={skill}>{skill}</li>))
                        }
                    </ul>
                </Grid>
            </Grid>
        </>
    );
};