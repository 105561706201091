import { makeStyles, Theme, darken } from '@material-ui/core/styles';

export const grabBarStyles = (theme: Theme) => ({
    width: theme.spacing(2),
    height: '100%',
    marginRight: `${theme.spacing(1)}px`,
    background: ` repeating-linear-gradient(
      315deg,
      transparent,
      transparent 1px,
      ${theme.palette.grey[400]} 2px,
      ${theme.palette.grey[400]} 3px
    );`,
});

export const useStyles = makeStyles((theme) => ({
  nav: {
    position: 'absolute',
    backgroundColor: theme.palette.primary.light,
    borderTop: `1px solid ${theme.palette.common.black}`,
    borderBottom: `1px solid ${theme.palette.common.black}`,
    padding: theme.spacing(1),
    paddingTop: 0,
  },
  grabBars: grabBarStyles(theme),
  // ADDRESS BAR STUFF
  addressBar: {
    borderTop: `1px solid ${theme.palette.grey[400]}`,
    paddingTop: `${theme.spacing(1)}px`,
  },
  addressText: {
    paddingRight: `${theme.spacing(2)}px`,
  },
  // URL INPUT
  inputRoot: {
    backgroundColor: darken(theme.palette.primary.light, 0.1),
  },
  inputInput: {
    fontWeight: 'bold !important' as any,
  },
  addressInputText: {
    color: theme.palette.common.white,
    transform: `translate(${theme.spacing(1)}px, ${theme.spacing(1)}px) scale(1)`,
  },
  notchedOutline: {
    borderColor: `${theme.palette.grey[500]} !important`,
    borderWidth: '1px !important',
  },
}));