import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(2),
    border: `1px solid ${theme.palette.common.black}`,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
  },
  folderIcon: {
    height: '50px',
  },
  label: {
    backgroundColor: 'transparent',
  }
}));