import React from 'react';

import { useStyles } from './browserContent.styles';

export const BrowserContent: React.FC = ({children}) =>  {
    const classes = useStyles();

    return (
        <div 
            className={classes.wrapper}>
            {children}
        </div>
    );
};