import { Button } from '@material-ui/core';
import React, { useState, ChangeEvent } from 'react'
import GetAppIcon from '@material-ui/icons/GetApp';
import { 
    Toolbar,
    Editor,
    BtnBold,
    BtnItalic,
    BtnUndo,
    BtnUnderline,
    BtnNumberedList,
    BtnBulletList,
 } from 'react-simple-wysiwyg';
import ResumePDF from '../../../assets/pdfs/KusoldResume.pdf';
import { resume } from './resume';

import { useStyles } from './simpleText.styles';

export const SimpleText = () => {
    const [content, setContent] = useState(resume);
    const classes = useStyles();

    function onChange(e: ChangeEvent<HTMLInputElement>) {
        setContent(e.target.value);
    }

    return (
        <div className={classes.editorWrapper}>
            <Editor
                value={content}
                onChange={onChange}>
                <Toolbar className={classes.toolbar}>
                    <BtnUndo className={classes.undo}/>
                    <BtnBold />
                    <BtnItalic />
                    <BtnUnderline />
                    <BtnNumberedList />
                    <BtnBulletList />
                    <Button
                        size="small"
                        classes={{
                            root: classes.download,
                            startIcon: classes.downloadIcon,
                        }}
                        startIcon={<GetAppIcon fontSize="inherit"/>}
                        href={ResumePDF}
                        download
                        >
                        Download PDF
                    </Button>
                </Toolbar>
            </Editor>
        </div>
    );
}

