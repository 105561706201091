import React from 'react';
import { Grid } from '@material-ui/core';
import { FullScreen, useFullScreenHandle } from "react-full-screen";

import { Screensaver } from './screensaver/screensaver';
import { AppIcons } from './appIcons/appIcons';
import { Topbar } from './topbar/topbar';
import { OpenWindows } from './openWindows/openWindows';

import { useStyles } from './desktop.styles';
import { useAppSelector } from '../../store/hooks';
import { selectTheme } from '../../store/theme/theme.slice';
import { getWallpaper } from '../../store/theme/wallpaper';

function getBackgroundStyles({img, tiled}: {img: string, tiled: boolean}){
    return tiled ? {
        backgroundImage: `url('${img}')`,
    } : {
        background: `url('${img}') no-repeat center center/cover fixed`,
    };
}

export const Desktop = () => {
    const handle = useFullScreenHandle();
    const selectedTheme = useAppSelector(selectTheme);
    const wallpaper = getWallpaper(selectedTheme);
    const classes = useStyles();
  
    return (
        <FullScreen handle={handle}>
            <Screensaver />
            <Grid 
                container
                direction="column"
                alignItems="stretch">
                <Grid item>
                    <Topbar triggerFullscreen={handle.enter}/>
                </Grid>
                <Grid 
                    item
                    className={classes.background}
                    style={getBackgroundStyles(wallpaper)}
                    container
                    justify="flex-start"
                    alignItems="flex-end"
                    >
                        <OpenWindows />
                        <AppIcons />
                </Grid>
            </Grid>
        </FullScreen>
    );
};
