import React, { useEffect, useState } from 'react';

import { useStyles } from './screensaver.styles';
import { useMediaQuery, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { Overlay } from '../../shared/overlay/overlay';

import screensaverImg from '../../../assets/images/wind_face.jpg';

export const Screensaver: React.FC = () => {
    const theme = useTheme();
    const [isIdle, setIdle] = useState(false);

    const S_IN_MIN = 60;
    const MS_IN_S = 1000;
    // 3 minutes
    const idleTime = 3 * S_IN_MIN * MS_IN_S;
    
    const classes = useStyles();

    useEffect(() => {
        let timeout: any;

        function onIdle(){
            setIdle(true);
        }
        const setTimeouts = () => {
            timeout = setTimeout(onIdle, idleTime);
        };
        
        const clearTimeouts = () => {
            if (timeout) clearTimeout(timeout);
            setIdle(false);
        };

        const events = [
            'load',
            'mousemove',
            'mousedown',
            'click',
            'scroll',
            'keypress'
        ];

        const resetTimeout = (e: any) => {
            clearTimeouts();
            setTimeouts();
        };

        for (let i in events) {
            window.addEventListener(events[i], resetTimeout);
        }

        setTimeouts();
        return () => {
            for(let i in events){
                window.removeEventListener(events[i], resetTimeout);
                clearTimeouts();
            }
        }
    }, [idleTime]);

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    return isIdle && !isMobile ?
            (
                <Overlay>
                    <div className={classes.screensaver}>
                        <ScreensaverImage />
                    </div>
                     
                </Overlay>
        ) : null;
};

const ScreensaverImage: React.FC = () => {
    const classes = useStyles();
    return (
        <div className={clsx(classes.screensaverSizing, classes.yMovement)}>
            <div className={classes.xMovement}>
                <img className={clsx(classes.screensaverSizing, classes.spin, classes.screensaverImg )} src={screensaverImg} alt="rotating face" />
            </div>
        </div>
    );
}