import { Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import {
    isBrowser,
    isMobile
  } from "react-device-detect";

import OsLogo from '../../../assets/images/heart.svg';
import FullscreenIcon from '../../../assets/images/fullscreen.svg';

import { useAppDispatch } from '../../../store/hooks';
import { openWindow, WindowType } from '../../../store/windows/windows.slice';
import { TopbarItem } from './topbarItem';
import { CurrentDate } from './currentDate';

import { useStyles } from './topbar.styles';

interface Props {
    triggerFullscreen: () => void;
}

export const Topbar: React.FC<Props> = ({triggerFullscreen}) => {
    const dispatch = useAppDispatch();
    const classes = useStyles();

    const theme = useTheme();
    const isTiny = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <header>
            <Grid container className={classes.root}>
                <Grid 
                    item
                    xs={isMobile ? 12 : 6}
                    sm={5}
                    container
                    justify="flex-start"
                    alignItems="center">
                        <Grid item>
                            <TopbarItem>
                                <Grid container
                                    alignItems="center">
                                    <Grid item>
                                        <img 
                                            src={OsLogo}
                                            alt="pixelated heart fading away"
                                            className={classes.icon} />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h6">
                                            Kusold OS
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </TopbarItem>
                        </Grid>
                        <Grid item>
                            <TopbarItem title="About" onClick={() => dispatch(openWindow({ type: WindowType.About }))}/>
                        </Grid>
                        <Grid item>
                            <TopbarItem title="Themes" onClick={() => dispatch(openWindow({ type: WindowType.Themes }))}/>
                        </Grid>
                </Grid>
                {isBrowser && (
                    <Grid 
                    item
                    xs={6}
                    sm={7}
                    container
                    justify="flex-end"
                    alignItems="center">
                            { !isTiny && (<Grid item className={classes.boxed}>
                                <TopbarItem onClick={triggerFullscreen}>
                                    <Grid 
                                        container
                                        alignItems="center">
                                        <Grid item>
                                            <img 
                                                src={FullscreenIcon}
                                                alt="Four corner icon"
                                                className={clsx(classes.icon, classes.fullscreenIcon)} />
                                        </Grid>
                                        <Grid item>
                                            Fullscreen
                                        </Grid>
                                    </Grid>
                                </TopbarItem>
                            </Grid>
                            )}
                        <Grid item className={classes.boxed}>
                            <TopbarItem>
                                <CurrentDate />
                            </TopbarItem>
                        </Grid>
                </Grid>
                )}
            </Grid>
        </header>
    );
}
