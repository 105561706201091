import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'absolute',
    bottom: 0,
    margin: `8px 4px`,
  },
  iconButton: {
    flexDirection: 'column',
  },
  icon: {
    height: '50px',
    margin: '0 auto',
  },
  label: {
    backgroundColor: theme.palette.primary.main,
    padding: '0px 2px',
    margin: '4px 0',
    fontSize: '12px',
  }
}));