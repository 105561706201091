import React from 'react';
import clsx from 'clsx';
import { $enum } from 'ts-enum-util';
import { Divider, Grid } from '@material-ui/core';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectTheme, setTheme, ThemeOption } from '../../../store/theme/theme.slice';
import { getWallpaper } from '../../../store/theme/wallpaper';
import { Button } from '../../shared/button/button';

import { useStyles } from './theme.styles';

export const Theme: React.FC = () =>  {
    const dispatch = useAppDispatch();
    const selectedTheme = useAppSelector(selectTheme);
    const wallpaper = getWallpaper(selectedTheme);
    const classes = useStyles();

    function changeTheme(themeOption: ThemeOption){
        dispatch(setTheme(themeOption));
    }
    return (
        <>
            <Grid
                container
                direction="row"
                alignItems="center"
                justify="center"
                className={classes.wrapper}>
                <Grid
                    xs={12}
                    item>
                        <div className={classes.center}>
                            <div className={classes.computer}>
                                <span 
                                    className={classes.preview}
                                    style={{
                                        backgroundImage:`url('${wallpaper.img}')`
                                    }}>
                                </span>
                            </div>
                            <Divider className={clsx(classes.center,classes.divider)}/>
                        </div>
                </Grid>
                <Grid 
                    xs={12}
                    item
                    container
                    direction="row"
                    className={classes.selectionBox}>
                    {$enum(ThemeOption).map((option: ThemeOption) => (
                        <Grid item xs={12}>
                            <Button 
                                key={option}
                                onClick={() => changeTheme(option)}
                                fullWidth
                                className={clsx(classes.option, option === selectedTheme ? classes.selectedOption : '')}>
                                    {option}
                            </Button>
                        </Grid>
                    ))}
                </Grid>
            </Grid>            
        </>
    );
};