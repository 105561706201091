import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from './store/hooks';
import { selectTheme } from './store/theme/theme.slice';
import { openWindow, selectOpenWindows, WindowType } from './store/windows/windows.slice';

import { Theme } from './theme/theme';
import { Desktop } from './components/desktop/desktop';
import { SHARABLE_URL_KEY } from './components/apps/apps.const';

ReactGA.initialize('UA-134195241-1');

function App() {  
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedTheme = useAppSelector(selectTheme)
  const openWindows = useAppSelector(selectOpenWindows);

  useEffect(() => {
    // SHARABLE URL SYNCING  
    const sharableState = searchParams.get(SHARABLE_URL_KEY);

    if(openWindows.length && sharableState !== openWindows[openWindows.length - 1].type){
      // syncing the last opened window with query params so someone could share a URL for an active window
      setSearchParams({[SHARABLE_URL_KEY]: openWindows[openWindows.length - 1].type}) 
    } else if(!openWindows.length && sharableState){
      // if there is one set and no windows are open, open it!
      dispatch(openWindow({type: (sharableState as WindowType)}));
    }
  })

  return (
    <ThemeProvider theme={Theme(selectedTheme)}>
      <CssBaseline />
      <Desktop />
    </ThemeProvider>
  );
}

export default App;