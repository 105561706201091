import { makeStyles, Theme } from '@material-ui/core/styles';

import undoSVG from '../../../assets/images/undo.svg';

const buttonStyling = (theme: Theme) => ({
    fontFamily: `${theme.typography.secondaryFontFamily} !important`,
    border: `1px solid ${theme.palette.common.black} !important`,
    margin: '0 2px',
    backgroundColor: `${theme.palette.common.white} !important`,
    '&:hover': {
        backgroundColor: `${theme.palette.grey[200]} !important`,
    }
});
export const useStyles = makeStyles((theme) => ({
  editorWrapper: {
      width: '100%',
      backgroundColor: theme.palette.common.white,
      '& > div': {
          border: 'none !important',
      },
      '& > div > div:first-of-type + div': {
            // this selector gets us the editable content
            cursor: 'text',
            marginTop: '10px',
            backgroundColor: theme.palette.common.white,
            '& > p, li': {
                cursor: 'text',
                fontFamily: theme.typography.secondaryFontFamily,
                '& i, b': {
                    fontFamily: theme.typography.fontFamily,
                }
            },
            '> *': {
                cursor: 'text',
            },
      },
  },
  toolbar: {
      backgroundColor: `${theme.palette.primary.dark} !important`,
      borderTop: `1px solid ${theme.palette.common.black} !important`,
      borderBottom: `1px solid ${theme.palette.common.black} !important`,
      padding: '2px 0',
      position:'fixed',
      width: '100%',
      '& > button': buttonStyling(theme),
  },
  undo: {
      fontSize: 0,
      '&:after': {
            fontSize: '14px',
            content: `url('${undoSVG}')`,
            display: 'block',
            height: '40px',
            width: '40px'
      }
  },
  download: {
      padding: '2px',
      ...buttonStyling(theme),
  },
  downloadIcon: {
    marginLeft: 0,
    marginRight: '4px',
    fontSize: '14px',
  },
}));