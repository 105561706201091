import { configureStore } from '@reduxjs/toolkit'
import ThemeReducer from './theme/theme.slice';
import WindowsReducer from './windows/windows.slice';
import BrowserReducer from './browser/browser.slice';

export const store = configureStore({
  reducer: {
      theme: ThemeReducer,
      windows: WindowsReducer,
      browser: BrowserReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch