import CatWallpaper from '../../assets/images/wallpaper/cat-wallpaper.png';
import BreezyWallpaper from '../../assets/images/wallpaper/breezy-wallpaper.gif';
import TexturedWallpaper from '../../assets/images/wallpaper/textured-wallpaper.png';
import BrightSun from '../../assets/images/wallpaper/fullscreen/brightSun.jpg';
import PurpleSun from '../../assets/images/wallpaper/fullscreen/purpleSun.jpg';
import { ThemeOption } from './theme.slice';

export function getWallpaper(theme: ThemeOption){
    switch(theme){
        case ThemeOption.Meow:
            return {
                tiled: true,
                img: CatWallpaper
            };
        case ThemeOption.Textured:
            return {
                tiled: true,
                img: TexturedWallpaper
            };
        case ThemeOption.Morning:
            return {
                tiled: false,
                img: BrightSun
            };
        case ThemeOption.Evening:
            return {
                tiled: false,
                img: PurpleSun,
            };
        case ThemeOption.Breezy:
        default:
            return {
                tiled: true,
                img: BreezyWallpaper
            };
    }
}