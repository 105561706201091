import React from 'react';
import { Button } from '../../shared/button/button';

interface Props {
    title?: string;
    onClick?: () => void;
}
export const TopbarItem: React.FC<Props> = ({title, onClick, children}) => {
    return onClick ? (
        <Button onClick={onClick}>
            {children ? children : title}
        </Button>
    ) : (
        <div>
            {children}
        </div>
    );
}
