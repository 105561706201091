import { IconButton, Snackbar } from '@material-ui/core';
import React, { useState } from 'react';
import { isIOS } from "react-device-detect";
import CloseIcon from '@material-ui/icons/CloseOutlined';

import { useStyles } from './design.styles';

export const Design: React.FC = () =>  {
    const classes = useStyles();
    const [isSnackbarOpen, setIsSnackbarOpen] = useState(isIOS);

    const handleClose = () => setIsSnackbarOpen(false);

    return (
        <>
        <Snackbar
            open={isSnackbarOpen}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={handleClose}
            message="Use two fingers to scroll"
            className={classes.snackbar}
            action={
                <>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={handleClose}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </>
            }
            />
            <iframe
                title="paint"
                src={process.env.PUBLIC_URL + '/jspaint/index.html'}
                sandbox='allow-scripts allow-same-origin'
                className={classes.wrapper}
                />        
        </>
    );
};