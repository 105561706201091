import { Grid, } from '@material-ui/core';
import React from 'react';

import IeIcon from '../../../../../assets/images/browser/Ie-98.png'
import BackIcon from '../../../../../assets/images/browser/BackBtn.png';
import BackDisabledIcon from '../../../../../assets/images/browser/BackBtnDisabled.png';
import ForwardIcon from '../../../../../assets/images/browser/ForwardBtn.png';
import ForwardDisabledIcon from '../../../../../assets/images/browser/ForwardBtnDisabled.png';
import RefreshIcon from '../../../../../assets/images/browser/RefreshBtn.png';
import HomeIcon from '../../../../../assets/images/browser/HomeBtn.png';

import { back, forward, navToPage, selectHistory } from '../../../../../store/browser/browser.slice';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { HOME_PAGE } from '../../../../apps/projects/dns.const';
import { SystemIcon } from '../../../systemIcon/systemIcon';
import { useStyles } from './browserControls.styles';

export const BrowserControls: React.FC = () =>  {
    const classes = useStyles();

    const history = useAppSelector(selectHistory);
    const currentHistoryIndex = useAppSelector((state) => state.browser.currentPage);

    const dispatch = useAppDispatch();
    return (
        <Grid 
            container
            direction="row"
            alignItems="center"
            justify="space-between">
                <Grid
                    item
                    xs={11}
                    container>
                        <Grid item>
                            <div className={classes.grabBars}></div>
                        </Grid>
                        <Grid item>
                            <SystemIcon
                                title="Back"
                                onClick={() => dispatch(back())}
                                icon={history.length > 1 ? BackIcon : BackDisabledIcon }
                                disabled={history.length <= 1}
                                alt="Navigate backwards in browser history"
                                iconClassName={classes.controlIcon}
                                labelClassName={classes.controlLabel}/>
                        </Grid>
                        <Grid item>
                            <SystemIcon
                                title="Forward"
                                onClick={() => dispatch(forward())}
                                icon={currentHistoryIndex < history.length - 1 ? ForwardIcon : ForwardDisabledIcon }
                                disabled={currentHistoryIndex >= history.length - 1}
                                alt="Navigate forwards in browser history"
                                iconClassName={classes.controlIcon}
                                labelClassName={classes.controlLabel}/>
                        </Grid>
                        <Grid item>
                            <SystemIcon
                                title="Refresh"
                                onClick={() => {}}
                                icon={RefreshIcon}
                                alt="Refresh the current page"
                                iconClassName={classes.controlIcon}
                                labelClassName={classes.controlLabel}/>
                        </Grid>
                        <Grid item >
                            <SystemIcon
                                title="Home"
                                onClick={() => dispatch(navToPage(HOME_PAGE.url))}
                                icon={HomeIcon}
                                alt="Go to the home page"
                                iconClassName={classes.controlIcon}
                                labelClassName={classes.controlLabel}/>
                        </Grid>
                </Grid>
                <Grid 
                    xs={1}
                    item
                    container
                    justify="flex-end">
                        <Grid item>
                            <div className={classes.ieIconWrapper}>
                                <img 
                                className={classes.ieIcon}
                                src={IeIcon}
                                alt="Internet Explorer pixelated 'e' icon"/>
                            </div>
                        </Grid>
                </Grid>
        </Grid>
    );
};