import { createMuiTheme } from '@material-ui/core/styles';
import { amber, blue, deepPurple, indigo, purple } from '@material-ui/core/colors';

import { ThemeOption } from '../store/theme/theme.slice';
import { typography, typographyGlobalOverrides } from './typography';


export const Theme = (themeOption: ThemeOption) => {
  const theming = getTheme(themeOption);
  return createMuiTheme({
    ...BaseTheme,
    ...theming,
  });
}

const SPACING = 4;

const BaseTheme = {
  typography,
  spacing: SPACING,
  shadows: new Array(25).fill(null)
    .map((v, index) => 
      `inset 1px 1px 0 0 #fff, ${index}px ${index}px 0 rgb(0 0 0 / 20%)`) as any,
  overrides: {
    MuiCssBaseline: {
      '@global': {
        'html, body, p, div': {
          cursor: 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAATCAYAAACk9eypAAAAAXNSR0IArs4c6QAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAhGVYSWZNTQAqAAAACAAFARIAAwAAAAEAAQAAARoABQAAAAEAAABKARsABQAAAAEAAABSASgAAwAAAAEAAgAAh2kABAAAAAEAAABaAAAAAAAAAEgAAAABAAAASAAAAAEAA6ABAAMAAAABAAEAAKACAAQAAAABAAAADKADAAQAAAABAAAAEwAAAAAChpcNAAAACXBIWXMAAAsTAAALEwEAmpwYAAABWWlUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iWE1QIENvcmUgNS40LjAiPgogICA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPgogICAgICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIgogICAgICAgICAgICB4bWxuczp0aWZmPSJodHRwOi8vbnMuYWRvYmUuY29tL3RpZmYvMS4wLyI+CiAgICAgICAgIDx0aWZmOk9yaWVudGF0aW9uPjE8L3RpZmY6T3JpZW50YXRpb24+CiAgICAgIDwvcmRmOkRlc2NyaXB0aW9uPgogICA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgpMwidZAAABqElEQVQoFY3SPUvDQBgH8BREpRHExYiDgmLFl6WC+AYmWeyLg4i7buJX8DMpOujgyxGvUYeCgzhUQUSKKLUS0+ZyptXh8Z5Ti621ekPyJHl+uftfomhaf9Ei5JyxXKfynyEA6EYcLHpwyflT958GAQ7DTABNHd8EbtDbEH2BD5QEQmi2mM8P/Iq+A0SzszEg+3sPjDnDdVEtQKQbMUidHD3xVzf6A9UDEmEm+8h9KTqTVUjT+vB53aHrCbAPiceYq1dQI1Aqv4EhMll0jzv+Y0yiRgCnLRSYyDQHVoqUXe4uKL9l+L7GXC4vkMhE6eW/AOJs9k583ORDUyXMZ8F5SVHVVnllmPNKSFagAJ5DofaqGXw/gHBYg51dIldkmknY3tguv3jOtHR4+MqAzaraJXbEhqHhcQlwGSOi5pytVQHZLN5s0WNe8HPrLYlFsO20RPHkImxsbmHdLJFI76th7Z4SeuF53hTeFLvhRCJRCTKZKxgdnRDbW+iozFJbBMw14/ElwGYc0egMBMFzT21f5Rog33Z7dX02GBm7WV5ZfT5Nn5bE3zuCDe9UxdTpNvK+5AAAAABJRU5ErkJggg==), auto',
        },
        ...typographyGlobalOverrides,
        html: {
          imageRendering: 'pixelated',
          overflowX: 'hidden',
          fontWeight: 100,
        },
        img: {
          userDrag: 'none', 
          userSelect: 'none',
          '-moz-user-select': 'none',
          '-webkit-user-drag': 'none',
          '-webkit-user-select': 'none',
          '-ms-user-select': 'none',
          imageRendering: 'pixelated',
        } as any,
        h1: {
          fontWeight: 100,
        },
        h2: {
          fontWeight: 100,
        },
        p: {
          fontWeight: 100,
        },
        b: {
          fontWeight: 100,
        },
        i: {
          fontWeight: 100,
        },
        body: {
          lineHeight: '1.4',
        }
      }
    } as any,
    MuiButton: {
      root: {
        textTransform: 'none' as any, // have to cast to any cause of MUI issue
        lineHeight: '1.4',
        paddingTop: '2px',
        borderRadius: 0,
        fontFamily: 'inherit',
      },
      text: {
        padding: '0px',
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: `${SPACING}px`,
      }
    },
    MuiSnackbarContent: {
      message: {
        fontFamily: typography.secondaryFontFamily,
      }
    }
  },
  props: {
    MuiOutlinedInput: {
      notched: false
    },
  }
}

const getTheme = (themeOption: ThemeOption) => {
  switch (themeOption){
    case ThemeOption.Breezy:
      return BreezyTheme;
    case ThemeOption.Textured:
      return TexturedTheme;
    case ThemeOption.Morning:
      return MorningTheme;
    case ThemeOption.Evening:
      return EveningTheme;
    case ThemeOption.Meow:
      return MeowTheme;
    default:
      return BreezyTheme;
  }
}

const BreezyTheme = {
  palette: {
    primary: {
      light: '#FFFFFF',
      main: blue[50],
      dark: '#b1bfca',
      
    },
    secondary: {
      main: blue[800],
    }
  },
};

const TexturedTheme = {
  palette: {
    primary: {
      light: '#FFFFFF',
      main: purple[50],
      dark: '#c0b3c2',
    },
    secondary: {
      main: purple[800],
    }
  },
};

const MorningTheme = {
  palette: {
    primary: {
      light: '#FFFFFF',
      main: amber[50],
      dark: '#ccc5af'
    },
    secondary: {
      main: amber[800],
    }
  },
};

const EveningTheme = {
  palette: {
    primary: {
      light: '#FFFFFF',
      main: indigo[50],
      dark: '#b6b8c3',
    },
    secondary: {
      main: indigo[800],
    }
  },
};

const MeowTheme = {
  palette: {
    primary: {
      light: '#FFFFFF',
      main: deepPurple[50],
      dark: '#bbb5c3'
    },
    secondary: {
      main: deepPurple[800],
    }
  },
};