import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    window: {
        border: `solid .5px ${theme.palette.common.black}`,
        boxShadow: theme.shadows[5],
        background: theme.palette.primary.main,
    },
    draggable: {
        cursor: 'move',
    },
}));