import React from 'react';
import { Button as MuiButton, ButtonProps as MuiButtonProps } from '@material-ui/core';
import { useAudio } from '../../useAudio/useAudio';
import clickSrc from '../../../assets/music/click.mp3';

export const Button: React.FC<MuiButtonProps> = ({children, onClick, ...buttonProps}) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, triggerSound] = useAudio(clickSrc);

    return (
        <MuiButton
            onClick={(e: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
                triggerSound();
                if (onClick){
                    onClick(e);
                }
            }}
            {...buttonProps}
            >
                {children}
        </MuiButton>
    );
};