import React, { MouseEvent } from 'react';
import { FileViewer } from '../../shared/fileViewer/fileViewer';

import miskaImg from '../../../assets/images/trash/miska.jpg';
import yearbookImg from '../../../assets/images/trash/yearbook.jpg';
import rickyImg from '../../../assets/images/trash/ricky.jpeg';

import { useAppDispatch } from '../../../store/hooks';
import { openWindow, WindowType } from '../../../store/windows/windows.slice';

export const Trash: React.FC = () =>  {
    const dispatch = useAppDispatch();

    const openImageCallback = (name: string, path: string, alt: string) => {
        return (e: MouseEvent<HTMLElement>) => {
            // prevents propogation so that the new trash photos show up on top of the
            // fileviewer
            e.stopPropagation();
            dispatch(openWindow({
                type: WindowType.Image,
                props: { 
                    name,
                    src: path,
                    alt
                },
            }));
        };
    }
    const TRASH_FILES = [
        {
            name: 'Trash_01.jpg',
            onClick: openImageCallback(
                'Trash_01.jpg',
                yearbookImg,
                'Yearbook photo of M as a child.',
            ),
        },
        {
            name: 'Trash_02.jpg',
            onClick: openImageCallback(
                'Trash_02.jpg',
                rickyImg,
                'Raccoon inspirational poster that says "Live like every day is Trash"',
            ),
        },
        {
            name: 'Trash_03.jpg',
            onClick: openImageCallback(
                'Trash_03.jpg',
                miskaImg,
                'Children dressed up for a Slovenian play involving farm animals. An 8 year old M is front and center wearing a mouse costume.'
            ),
        },
    ];

    return (
        <FileViewer files={TRASH_FILES}/>        
    );
};