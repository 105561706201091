import { v4 as uuidv4 } from 'uuid';
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ImageViewerProps } from '../../components/apps/trash/imageViewer/imageViewer'
import type { RootState } from '../store'

export enum WindowType {
    // Top bar
    About="About",
    Themes="Themes",
    // Desktop Apps
    Design="Design",
    Projects="Development",
    Games="Games",
    Resume="Resume.txt",
    Trash="Trash",
    // Secondary
    Image="Image",
}

interface WindowInfo {
  type: WindowType;
  props?: ImageViewerProps;
}
interface OpenWindow {
  id: string;
  type: WindowType;
  props?: ImageViewerProps;
}
interface WindowsState {
  windows: Array<OpenWindow>,
}

const initialState: WindowsState = {
  // stack representing current open windows
  windows: [],
}

function shallowEqual(object1: any, object2: any) {
  const keys1 = object1 ? Object.keys(object1) : [];
  const keys2 = object2 ? Object.keys(object2) : [];

  if (keys1?.length !== keys2?.length) {
    return false;
  }

  for (let key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }

  return true;
}

export const windowsSlice = createSlice({
  name: 'windows',
  initialState,
  reducers: {
    openWindow: (state, {payload: { type, props }}: PayloadAction<WindowInfo>) => {
      let id = uuidv4();
      // eslint-disable-next-line eqeqeq
      const isWindowAlreadyOpen = state.windows.some(
        (openWin) =>
          {
            return openWin.type === type && shallowEqual(openWin.props, props);
          }
      );
      if(isWindowAlreadyOpen){
          // reorder so that this window's index and subsequent visual zIndex is now on top
          const fromIndex = state.windows.findIndex((openWin) => openWin.type === type && shallowEqual(openWin.props, props));
          const removed = state.windows.splice(fromIndex, 1);
          if(removed.length){
            id = removed[0].id;
          }
      }
      state.windows.push({
        id,
        type,
        props});
    },
    closeWindow: (state, {payload: id}: PayloadAction<string>) => {
      const location = state.windows.findIndex((openWin) => openWin.id === id)
      if(location !== -1){
        // remove window from stack and close it
        state.windows.splice(location, 1);
      }
    },
    bringToTop: (state, {payload: id}: PayloadAction<string>) => {
      const location = state.windows.findIndex((openWin) => openWin.id === id)
      if(location !== -1){
        // store window
        const old = state.windows[location];
        // remove
        state.windows.splice(location, 1);
        // re add to front
        state.windows.push(old);
      }
    },
  },
})

export const { 
  openWindow,
  closeWindow,
  bringToTop
} = windowsSlice.actions

export const selectOpenWindows = (state: RootState) => state.windows.windows

export default windowsSlice.reducer