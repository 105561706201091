import React from 'react';
import ReactGA from 'react-ga';
import { Grid } from '@material-ui/core';

import { useStyles } from './appIcons.styles';
import { openWindow, WindowType } from '../../../store/windows/windows.slice';
import { useAppDispatch } from '../../../store/hooks';
import { SystemIcon } from '../../shared/systemIcon/systemIcon';

import EditIcon from '../../../assets/images/appIcons/edit_icon.png';
import InternetIcon from '../../../assets/images/browser/Ie-98.png';
import PaintIcon from '../../../assets/images/appIcons/paint.png';
import FullTrashIcon from '../../../assets/images/appIcons/full_trash_icon.png';

const APPS = [
    {
        type: WindowType.Design,
        icon: PaintIcon,
    },
    {
        type: WindowType.Projects,
        icon: InternetIcon,
    },
    {
        type: WindowType.Resume,
        icon: EditIcon,
    },
];

export const AppIcons = () => {
    const dispatch = useAppDispatch();
    const classes = useStyles();
    return (
        <Grid
            container
            direction="row"
            className={classes.wrapper}>
            <Grid 
                item
                xs={10}
                container
                spacing={2}>
                {APPS.map(({type, icon}) => (
                    <Grid item key={type}>
                        <SystemIcon 
                            title={type} 
                            onClick={() => {
                                dispatch(openWindow({type}));
                                ReactGA.event({
                                    category: 'App',
                                    action: `Opened ${type}`
                                  });
                            }}
                             icon={icon}/>
                    </Grid>
                ))}
            </Grid>
            <Grid
                item
                container
                justify="flex-end"
                xs={2}>
                <Grid item key={WindowType.Trash}>
                    <SystemIcon 
                        title={WindowType.Trash}
                        onClick={() => {
                            dispatch(openWindow({type: WindowType.Trash}));
                            ReactGA.event({
                                category: 'App',
                                action: `Opened ${WindowType.Trash}`
                              });
                        }}
                        icon={FullTrashIcon}/>
                </Grid>
            </Grid>
        </Grid>
            
    );
};
