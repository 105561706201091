import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
  },
  snackbar: {
    width: '60vw',
    marginLeft: '20vw',
    marginTop: '1.5rem',
  }
}));