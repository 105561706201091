import { makeStyles } from '@material-ui/core/styles';
import { BAR_HEIGHT } from '../browserNav/browserControls/browserControls.styles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: theme.palette.common.white,
    margin: theme.spacing(1),
    minHeight: `calc(100% -  (2 * ${BAR_HEIGHT}))`,
    marginTop: `calc(2 * ${BAR_HEIGHT})`,
    border: `1px solid ${theme.palette.common.black}`,
    borderTop: 'none',
    fontFamily: '"Times New Roman", Times, serif',
    '& > *:first-child': {
      // ensuring no elements push the browser content down
      marginTop: 0,
    }
  },
}));