import { makeStyles, darken } from '@material-ui/core/styles';

import { grabBarStyles } from '../browserNav.styles';

export const BAR_HEIGHT = '40px';

export const useStyles = makeStyles((theme) => ({
  grabBars: grabBarStyles(theme),
  ieIconWrapper: {
    padding: `${theme.spacing(.5)}px ${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(.5)}px`,
    width: `calc(${BAR_HEIGHT} + ${theme.spacing(1.5)}px)`,
    height: `calc(${BAR_HEIGHT} + ${theme.spacing(1.5)}px)`,
    border: `1px solid ${theme.palette.grey[400]}`,
  },
  ieIcon: {
    width: `calc(${BAR_HEIGHT})`,
    height: `calc(${BAR_HEIGHT})`,
    padding: theme.spacing(1),
    backgroundColor: darken(theme.palette.primary.light, 0.1),
  },
  controlIcon: {
    width: '30px',
    height: '30px',
  },
  controlLabel: {
    margin: -3,
    backgroundColor: 'transparent',
    fontSize: '11px',
  },
}));