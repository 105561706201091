import React from 'react';

import fourOhFourImg from '../../../../../assets/images/browser/404.gif';

import { useStyles } from './fourOhFour.styles';

export const FourOhFour: React.FC = () =>  {
    const classes = useStyles();
    return (
        <div className={classes.wrapper}>
            <h1>404 Page Not Found</h1>
            <img src={fourOhFourImg} alt="404. The decade you are trying to visit does not exist." />
        </div>
    );
};