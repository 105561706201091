import React, { MouseEvent } from 'react';
import { Grid } from '@material-ui/core';
import { SystemIcon } from '../systemIcon/systemIcon';

import folderIcon from '../../../assets/images/folder.png';

import { useStyles } from './fileViewer.styles';

interface File {
    name: string;
    onClick: (e: MouseEvent<HTMLElement>) => void;
}

interface Props {
    files: Array<File>;
}

export const FileViewer: React.FC<Props> = ({files}) =>  {
    const classes = useStyles();
    return (
        <Grid
            container
            direction="row"
            justify="flex-start"
            className={classes.wrapper}>
                {
                    files.map(({name, onClick}) => (
                        <Grid item key={name} xs={3}>
                            <SystemIcon 
                                title={name}
                                onClick={(e) => onClick(e)}
                                icon={folderIcon}
                                labelClassName={classes.label}/>
                        </Grid>
                    ))
                }
        </Grid>            
    );
};