import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

// I use the same div here that I mount my app into
// so the modal will be a sibling of the rest of the app
// in the DOM hierachy
const modalRoot = document.getElementById('root') as HTMLElement;

export const Overlay: React.FC = ({children}) => {
    const el = document.createElement('div');
    useEffect( () => {
        modalRoot.appendChild(el);
        return () => {
            modalRoot.removeChild(el);
        }
     }, [el]);
    return ReactDOM.createPortal(children, el);
}