import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'
import { AVAILABLE_WEB_PAGES, HOME_PAGE, PageInfo, } from '../../components/apps/projects/dns.const';


interface BrowserState {
  // index of the browserStack that should be displayed to the user
  currentPage: number;
  // stack represents browser history so you can go back and forward
  // last item on the stack is the page that's currently open
  browserStack: Array<string>,
}

const initialState: BrowserState = {
  currentPage: 0,
  browserStack: [HOME_PAGE.url],
}

export const browserSlice = createSlice({
  name: 'browser',
  initialState,
  reducers: {
    navToPage: (state, {payload: url}: PayloadAction<string>) => {
      // add page onto the stack
      state.browserStack.push(url);
      state.currentPage = state.browserStack.length - 1;
    },
    back: (state) => {
      state.currentPage = state.currentPage - 1 > 0 ? state.currentPage - 1 : 0;
    },
    forward: (state) => {
      state.currentPage = state.browserStack.length - 1 > state.currentPage + 1 ?
        state.currentPage + 1 : state.browserStack.length - 1;
    }
  },
})

export const { 
  navToPage,
  back,
  forward
} = browserSlice.actions

function getPageInfo(url: string): PageInfo | undefined {
  const lowerUrl = url.toLowerCase();
  return AVAILABLE_WEB_PAGES.find((webPage: PageInfo) => 
    lowerUrl === webPage.url.toLowerCase())
}

export const selectCurrentPageUrl = (state: RootState) => state.browser.browserStack[state.browser.currentPage];
export const selectCurrentPage = (state: RootState) => getPageInfo(state.browser.browserStack[state.browser.currentPage]);
export const selectHistory = (state: RootState) => state.browser.browserStack;

export default browserSlice.reducer;