import React from 'react';

import RocScreenshot from '../../../../../assets/images/browser/portfolio/ROCScreenshot.png';
import D2 from  '../../../../../assets/images/browser/portfolio/D2.png';
import { useStyles } from './home.styles';
import { PortfolioItem } from './portfolioItem/portfolioItem';

export const Home: React.FC = () =>  {
    const classes = useStyles();
    return (
        <div className={classes.wrapper}>
            <h1 className={classes.title}>Cool Projects I've Worked On</h1>
            <p className={classes.center}>See my <a href="https://www.linkedin.com/in/mkusold/" target="_blank" rel="noreferrer" >LinkedIn</a> for more</p>
            <PortfolioItem 
                title="🦺 Autonomous Construction Vehicle App 🦺"
                titleLink="https://www.freshconsulting.com/portfolio/robot-operational-control-application/"
                overview="Desktop app to manage and monitor vehicles, define geographic sites, and plan global vehicle routes"
                roles={['Dev Lead', 'Architect', 'Intermittent Project Manager', 'Scrum Master', 'Mentor of New Developers']}
                skills={['GIS','Construction Safety', 'Electron', 'React', 'Redux', 'Openlayers', 'Node.js', 'GraphQL']}
            />
            <div className={classes.image}>
                <img 
                    src={RocScreenshot}
                    alt="Screenshot of the ROC application" />
            </div>
            <q>
                M was able to understand, but more importantly, simplify some very complicated concepts for our team.
                <br/>
                As a designer, I believe it's rare to have such an easy working relationship with your dev lead. M's expertise, design-advocacy, and open mindedness helped us create some incredibly innovative (and fun) work together. M listens extremely well, and, because of this, is able to truly understand where the design team is coming from and create a realistic, yet bar-raising solution.
            </q>
            <span className={classes.mini}>-Mark Nader (Lead Designer)</span>

            <PortfolioItem 
                title="🏒 Robotic Air Hockey Table 🏒"
                overview="Autonomous robotic air hockey table for a trade show"
                roles={['Interactive Designer (UI, Sound, Light, Gameplay)', 'Interactions Architect', 'Fullstack Developer']}
                skills={['Figma','Angular', 'ROS (python)', 'Arduino (C++)', 'Docker', 'Kiosk Deployment']}
            />
             <div className={classes.image}>
                <img 
                    src={D2}
                    alt="Air hockey table and various interface mockups" />
            </div>

            <PortfolioItem 
                title="⚖️ Legal Discovery Web App ⚖️"
                overview="A web application that helps lawyers organize documents in preparation for trial"
                roles={['FrontEnd Developer']}
                skills={['Angular','PDF manipulation']}
            />
        </div>
    );
};