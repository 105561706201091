import { makeStyles } from '@material-ui/core/styles';
import computer from '../../../assets/images/computer.png';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    paddingBottom: '2rem',
  },
  center: {
      margin: '0 auto',
  },
  computer: {
    background: `url('${computer}')`,
    width: '194px',
    height: '176px',
    backgroundSize: '194px auto',
    display: 'block',
    margin: 'auto',
    position: 'relative',
    marginBottom: '2em',
  },
  preview: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.palette.common.black,
    width: '160px',
    height: '119px',
    position: 'absolute',
    top: '16px',
    left: '17px',
    imageRendering: 'auto',
  },
  divider: {
    width: '75%',
    minWidth: '100px',
    maxWidth: '300px',
  },
  selectionBox: {
    marginTop: '.5rem',
    border: `.5px solid ${theme.palette.common.black}`,
    width: '75%',
    minWidth: '100px',
    maxWidth: '300px',
    overflowY: 'scroll',
    height: '25%',
  },
  option: {
    justifyContent: 'flex-start',
    paddingLeft: '5px',
  },
  selectedOption: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    }
  },
}));