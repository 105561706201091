import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { loadState, saveState } from '../sessionStorage';
import type { RootState } from '../store'

export enum ThemeOption {
    Breezy="Breezy",
    Meow="Meowmaster",
    Textured="Alien Brain",
    Morning="Morning",
    Evening="Evening"
}

export interface ThemeState {
  theme: ThemeOption,
}

const persistedState: any = loadState();

const initialState: ThemeState = {
  theme: ThemeOption.Breezy,
  ...persistedState,
}

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setTheme: (state, action: PayloadAction<ThemeOption>) => {
      state.theme = action.payload;
      saveState(state);
    },
  },
})

export const { setTheme } = themeSlice.actions

export const selectTheme = (state: RootState) => state.theme.theme

export default themeSlice.reducer