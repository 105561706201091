import { makeStyles } from '@material-ui/core/styles';
import { isMobile } from "react-device-detect";
export const WINDOW_TOPBAR_HEIGHT = '23px';

const mobileCloseExpander = () => {
    return isMobile ? {
        '&:before': {
            content: "'Close'",
            padding: '0 5px 0 2px',
        }
    } : {};
}

export const useStyles = makeStyles((theme) => ({
    title: {
        padding: `${theme.spacing(1)}px`,
        paddingBottom: 0,
    },
    fullWidth: {
        flex: 1,
    },
    spacer: {
        marginTop: theme.spacing(1),
        height: `calc(${WINDOW_TOPBAR_HEIGHT} - ${theme.spacing(2)}px)`,
        background: ` repeating-linear-gradient(
            0deg,
            transparent,
            transparent 1px,
            ${theme.palette.common.black} 1px,
            ${theme.palette.common.black} 2px
          );`,
    },
    close: {
        cursor: 'pointer',
        border: `1px solid ${theme.palette.common.black}`,
        margin: `${theme.spacing(1)}px`,
        padding: `${theme.spacing(.8)}px ${theme.spacing(.8)}px ${theme.spacing(.4)}px ${theme.spacing(.8)}px`, 
        lineHeight: 1,
        minWidth: '5px',
        borderRadius: 0,
        fontSize: '8px',
        ...mobileCloseExpander(),
    }
}));