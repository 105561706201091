import { makeStyles } from '@material-ui/core/styles';

const rainbowText = {
  backgroundImage: 'repeating-linear-gradient(45deg, violet, indigo, blue, green, goldenrod, orange, red, violet)',
  textAlign: 'center',
  backgroundSize: '800% 800%',
  '-webkit-background-clip': 'text',
  color: 'transparent',
  fontSize: '24px',
  animation: '$rainbow 8s ease infinite',
};

export const useStyles = makeStyles((theme) => ({
  "@keyframes rainbow": { 
    '0%': {
      backgroundPosition: '0% 50%',
    },
    '50%': {
      backgroundPosition: '100% 25%',
    },
    '100%': {
      backgroundPosition: '0% 50%',
    },
  },
  
  wrapper: {
    padding: '0 2em',
    backgroundColor: theme.palette.grey[200],
    '& h1': {
      ...rainbowText,
    },
    '& h1,h2,h3,h4': {
      fontWeight: 'bold',
    },
    '& img': {
      width: '100%',
      padding: '1em 0',
    },
    '& q': {
      color: theme.palette.grey[700],
      fontStyle: 'italic',
    }
  },
  center: {
    textAlign: 'center',
  },
  title: {
    margin: '.25em',
  },
  image: {
    width: '75%',
    maxWidth: '700px',
  },
  mini: {
    fontSize: '12px',
  }
}));