import React, { MouseEvent } from 'react';
import clsx from 'clsx';

import { useStyles } from './systemIcon.styles';
import { Button } from '../button/button';

interface IconProps {
    title: string;
    onClick: (e: MouseEvent<HTMLElement>) => void;
    icon: string;
    disabled?: boolean
    alt?: string;
    labelClassName?: string;
    iconClassName?: string;
}

export const SystemIcon: React.FC<IconProps> = ({
    title,
    onClick,
    icon,
    alt,
    labelClassName,
    iconClassName,
    disabled=false}) => {
    const classes = useStyles();
    return (
        <Button 
            classes={{
                label: classes.iconButton
            }}
            onClick={(e) => onClick(e)}
            disabled={disabled}>
            <img
                className={clsx(classes.icon, iconClassName)}
                alt={alt ?? title}
                src={icon}
            />
            <p className={clsx(classes.label, labelClassName)}>{title}</p>
        </Button>
    )
}